import React, { createContext, useContext, useState } from 'react';

// Create the context
const ParticipantsContext = createContext();

// Create the provider component
export const ParticipantsProvider = ({ children }) => {
  const [participants, setParticipants] = useState([]);

  return (
    <ParticipantsContext.Provider value={{ participants, setParticipants }}>
      {children}
    </ParticipantsContext.Provider>
  );
};

// Custom hook to use the Participants context
export const useParticipants = () => {
  return useContext(ParticipantsContext);
};