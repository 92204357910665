import React, { useEffect, useMemo } from "react";
import store from "../../store/store";
import { PlaceholderAvatar } from "./PlaceholderAvatar";
import { createPortal } from "react-dom";

const VideoSection = ({ identity }) => {
  const participants = useMemo(() => store.getState().participants || [], []);
  const participant = participants.find((p) => p.identity === identity);

  useEffect(() => {
    participants.forEach((p) => {
      const el = document.getElementById(p.socketId);
      if (!el) return;

      createPortal(<PlaceholderAvatar participant={p} show={true} />, el);
    });
  }, [participants]);

  useEffect(() => {
    participants.forEach(() => {
      const el = document.getElementById(participant?.socketId);
      if (el) {
        el.style.display =
          participant?.videoDisabled === true ? "none" : "inherit";
      }
    });
  }, [participants, participant]);

  return (
    <div className="video_section_container">
      <div id="videos_portal">
        {/* <PlaceholderAvatar participant={participant} /> */}
      </div>
    </div>
  );
};

export default VideoSection;
