import axios from "axios";


axios.defaults.headers = {
  Authorization : process.env.REACT_APP_VID_AUTH_KEY
}
 //const serverApi = "https://binosphere.com:8081/api";
 const serverApi = `${process.env.REACT_APP_API_URL}/api`;
//const serverApi = "http://localhost:5005/api";
export const getRoomExists = async (roomId) => {
  console.log(roomId, process.env.REACT_APP_API_URL,roomId)
  const response = await axios.get(`${serverApi}/rooms/room-exists?roomId=${roomId}`);
  console.log("RESPONX....", response)
  return response.data;
};

export const getTURNCredentials = async () => {
  const response = await axios.get(`${serverApi}/get-turn-credentials`);
  console.log("TURNS", response, process.env.REACT_APP_API_URL);
  return response.data;
};
