import React from "react";

import store from "../../store/store";
import { LiaVideoSlashSolid } from "react-icons/lia";

const Avatar = () => {
  const participants = store
    .getState()
    .participants.filter((p) => p.videoDisabled === true);

  return (
    <div>
      {participants.map((participant, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            paddingTop: "32px",
            paddingBottom: "32px",
            paddingLeft: "32px",
            paddingRight: "32px",
            border: "2px solid blue",
            left: `${5 + index * 25}%`,
            bottom: "120px",
            display: "flex",
            alignItems: "center",
            // justifyContent: 'center',
            borderRadius: "10px",
          }}
        >
          <LiaVideoSlashSolid color="white" size={45} />
          <p style={{ color: "white", marginLeft: "8px" }}>
            {participant.identity}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Avatar;

// import React from 'react';
// import { BsFillPersonFill } from "react-icons/bs";

// const Avatar = ({ identities }) => {
//   return (
//     <div>
//       {identities.map((identity, index) => (
//         <div
//           key={index}
//           style={{
//             position: 'absolute',
//             paddingTop: '32px',
//             paddingBottom: '32px',
//             paddingLeft: '32px',
//             paddingRight: '32px',
//             border: '2px solid blue',
//             left: `${5 + index * 10}%`,
//             bottom: '25%',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             borderRadius: '10px',
//           }}
//         >
//           <BsFillPersonFill color="white" size={45} />
//           <p style={{ color: "white" }}>{identity}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Avatar;
