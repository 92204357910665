import React from "react";
import { ReactComponent as InCallMessagesButtonImg } from "../../resources/images/chats.svg";


const InCallMessagesButton = ({ handleChatContainerToggle }) => {

  return (
    <div className="video_button_container">
       <InCallMessagesButtonImg className="video_button_image" onClick={handleChatContainerToggle}/> 
    </div>
  );
};

export default InCallMessagesButton;

