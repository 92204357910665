import React from "react";
import ChatLabel from "./ChatLabel";
import Messages from "./Messages";
import NewMessage from "./NewMessage";

const ChatSection = ({isChatContainerOpen, handleChatContainerToggle}) => {
  return (
    <div className="chat_section_container" style={{translate: isChatContainerOpen ? '0px': '120% 0px', width: isChatContainerOpen ? '40%' : '0px', display: isChatContainerOpen ? 'block' : 'none'}}>
      <ChatLabel handleChatContainerToggle={handleChatContainerToggle}/>
      <Messages />
      <NewMessage />
    </div>
  );
};

export default ChatSection;
