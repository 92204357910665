// import React, { useState } from "react";

// // import CameraButtonImg from "../../resources/images/camera.svg";
// // import CameraButtonImgOff from "../../resources/images/cameraOff.svg";
// import * as webRTCHandler from "../../utils/webRTCHandler";
// import { LiaVideoSlashSolid, LiaVideoSolid } from "react-icons/lia";
// import Avatar from "./Avatar";

// const CameraButton = () => {
//   const [isLocalVideoDisabled, setIsLocalVideoDisabled] = useState(false);

//   const handleCameraButtonPressed = () => {
//     webRTCHandler.toggleCamera(isLocalVideoDisabled);
//     setIsLocalVideoDisabled(!isLocalVideoDisabled);
//   };

//   return (
//     <div className="video_button_container">
//       {/* <img
//         src={isLocalVideoDisabled ? CameraButtonImgOff : CameraButtonImg}
//         className="video_button_image"
//         onClick={handleCameraButtonPressed}
//       /> */}

// <div  className="video_button_image"
//         onClick={handleCameraButtonPressed}>{isLocalVideoDisabled ? <LiaVideoSlashSolid/>: <LiaVideoSolid />}  </div>
//         {isLocalVideoDisabled && <Avatar/>}

//     </div>

//   );
// };

// export default CameraButton;

import React from "react";
import * as webRTCHandler from "../../utils/webRTCHandler";
import { LiaVideoSlashSolid, LiaVideoSolid } from "react-icons/lia";
import Avatar from "./Avatar";
import { useLocalVideo } from "../../context/localVideoManager";
import store from "../../store/store";
import { setVideoStatusOfParticipants } from "../../store/actions";

// import { useParticipants } from "../../context/participantsContext";

const CameraButton = ({ identity }) => {
  // const { participants} = useParticipants();

  // console.log("please lord lets see participants", participants)

  const { isLocalVideoDisabled, toggleLocalVideo } = useLocalVideo();

  const handleCameraButtonPressed = () => {
    const state = store.getState();
    const { identity } = state;
    webRTCHandler.sendData({
      key: "update-participants",
      data: { identity, isLocalVideoDisabled: !isLocalVideoDisabled },
    });
    store.dispatch(
      setVideoStatusOfParticipants(identity, !isLocalVideoDisabled)
    );

    webRTCHandler.toggleCamera(isLocalVideoDisabled);
    toggleLocalVideo();
  };

  return (
    <div className="video_button_container">
      <div className="video_button_image" onClick={handleCameraButtonPressed}>
        {isLocalVideoDisabled ? <LiaVideoSlashSolid /> : <LiaVideoSolid />}
      </div>
      <Avatar identity={identity} />
    </div>
  );
};

export default CameraButton;
