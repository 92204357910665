import React from "react";
import { BsFillPersonFill } from "react-icons/bs";

export const PlaceholderAvatar = ({ participant = undefined, show = false }) => {
  return (participant?.videoDisabled === true || show) && (
    <div
      style={{
        padding: "32px",
        border: "2px solid blue",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        height: "120px",
        minWidth: "250px"
      }}
    >
      <BsFillPersonFill color="white" size={45} />
      <p style={{ color: "white", marginLeft: "8px" }}>
        {participant.identity}
      </p>
    </div>
  );
};
