import React from "react";
import CameraButton from "./CameraButton";
import LeaveRoomButton from "./LeaveRoomButton";
import MicButton from "./MicButton";
import SwitchToScreenSharingButton from "./SwitchToScreenSharingButton";
import { connect } from "react-redux";
import InCallMessagesButton from "./InCallMessagesButton";
import Timer from "../../components/Timer";

const VideoButtons = (props) => {
  const { connectOnlyWithAudio, handleChatContainerToggle, identity } = props;

  return (
    <div className="video_buttons_container">
      <div style={{display: "flex", columnGap: "10px"}}>
      <InCallMessagesButton handleChatContainerToggle={handleChatContainerToggle}/>
      <MicButton />
      {!connectOnlyWithAudio && <CameraButton identity={identity} />}
      {!connectOnlyWithAudio && <SwitchToScreenSharingButton />}
      <LeaveRoomButton />
      </div>
      <Timer/>
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(VideoButtons);
