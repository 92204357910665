import React, { useEffect, useState } from "react";
import ChatSection from "./ChatSection/ChatSection";
import VideoSection from "./VideoSection/VideoSection";

import { connect } from "react-redux";
import * as webRTCHandler from "../utils/webRTCHandler";
import Overlay from "./Overlay";

import "./RoomPage.css";
import VideoButtons from "./VideoSection/VideoButtons";

const RoomPage = ({
  roomId,
  identity,
  isRoomHost,
  showOverlay,
  connectOnlyWithAudio,
}) => {
  const [isChatContainerOpen, setChatContainerOpen] = useState(false);

  const handleChatContainerToggle = () => {
    setChatContainerOpen(!isChatContainerOpen);
  };

  useEffect(() => {
    if (!isRoomHost && !roomId) {
      const siteUrl = window.location.origin;
      window.location.href = siteUrl;
    } else {
      webRTCHandler.getLocalPreviewAndInitRoomConnection(
        isRoomHost,
        identity,
        roomId,
        connectOnlyWithAudio
      );
    }
  }, [isRoomHost, roomId, identity, connectOnlyWithAudio]);

  return (
    <div style={{ height: "100vh", overflow: "hidden", width: "100vw" }}>
      {showOverlay && <Overlay />}
      <div className="room_container">
        {/* <ParticipantsSection /> */}
        <VideoSection
          handleChatContainerToggle={handleChatContainerToggle}
          identity={identity}
        />
        {/* {!useLocalVideo && identity && <Avatar handleChatContainerToggle={handleChatContainerToggle} identity={identity} />} */}

        <ChatSection
          isChatContainerOpen={isChatContainerOpen}
          handleChatContainerToggle={handleChatContainerToggle}
        />
      </div>
      {!showOverlay && (
        <VideoButtons
          handleChatContainerToggle={handleChatContainerToggle}
          identity={identity}
        />
      )}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(RoomPage);
