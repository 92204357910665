import React, { createContext, useContext, useState } from "react";

// Create a context for the local video state
const LocalVideoContext = createContext();

export const LocalVideoProvider = ({ children }) => {
  const [isLocalVideoDisabled, setIsLocalVideoDisabled] = useState(false);

  const toggleLocalVideo = () => {
    setIsLocalVideoDisabled(prevState => !prevState);
  };

  return (
    <LocalVideoContext.Provider value={{ isLocalVideoDisabled, toggleLocalVideo }}>
      {children}
    </LocalVideoContext.Provider>
  );
};

export const useLocalVideo = () => {
  return useContext(LocalVideoContext);
};

