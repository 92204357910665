import React from "react";
import closeIcon from '../../resources/images/Union.png'


const ChatLabel = ({handleChatContainerToggle}) => {
  const altImage = "s1"
  
  return (
    <div className="chat_label_container">
      <p className="chat_label_paragraph">In-Call Messages</p>
      <div className="close_button" style={{cursor: "pointer"}}>
      <img src={closeIcon} alt={altImage} onClick={() =>handleChatContainerToggle() } />
      </div>
    </div>
  );
};

export default ChatLabel;
