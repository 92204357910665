// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { connect } from "react-redux";
// import { setIsRoomHost, setIdentity, setRoomId } from "../store/actions";
// import JoinRoomTitle from "./JoinRoomTitle";
// import JoinRoomContent from "./JoinRoomContent";
// import { useHistory } from "react-router-dom";
// import { getRoomExists } from "../utils/api";


// import "./JoinRoomPage.css";

// const JoinRoomPage = (props) => {
//   const { setIsRoomHostAction,roomId, setIdentityAction, setRoomIdAction,isRoomHost } = props;
  
//   const history = useHistory();
//   const search = useLocation().search;
//   const [errorMessage, setErrorMessage] = useState(null);
//   let  meetingId  = "";



//   useEffect( async () => {
//     const isRoomHost = new URLSearchParams(search).get("host");
//     meetingId = new  URLSearchParams(search).get("meetingId");
//     const name = new URLSearchParams(search).get("name");
//     console.log("MI",meetingId,name,isRoomHost);
    
//     if (isRoomHost) {
//       setIsRoomHostAction(true);
//     }

//     if(meetingId)
//         setRoomIdAction(meetingId);
    
//     if(name)
//         setIdentityAction(name);
//     console.log("MI_111",roomId, isRoomHost);

//     if(name && isRoomHost){
//        createRoom()
//     }else if (meetingId && name && !isRoomHost){

//       console.log("got here ....")
//        await joinRoom();
//     }
       
               
//   }, []);

//   const joinRoom = async () => {
//     console.log("ountunda", "xxxx");
//     const responseMessage = await getRoomExists(meetingId);
//     console.log(meetingId, "MID");

//     const { roomExists, full } = responseMessage;

//     if (roomExists) {
//       if (full) {
//         setErrorMessage("Meeting is full. Please try again later.");
//       } else {
//         // join a room !
//         setRoomIdAction(meetingId);
//         history.push("/room");
//       }
//     } else {
//       setErrorMessage("Meeting not found. Check your meeting id.");
//     }
//   };

//   const createRoom = () => {
//     history.push("/room");
//   };

//   return (
//     <div className="join_room_page_container">
//       <div className="join_room_page_panel">
//         <JoinRoomTitle 
//             isRoomHost={isRoomHost}
//         />
//         <JoinRoomContent />
//         {errorMessage && <p className="error_message">{errorMessage}</p>}
//       </div>
//     </div>
//   );
// };

// const mapStoreStateToProps = (state) => {
//  console.log(state);
//   return {
//     ...state,
//   };
// };

// const mapActionsToProps = (dispatch) => {
//   return {
//     setRoomIdAction: (roomId) => dispatch(setRoomId(roomId)),
//     setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
//     setIdentityAction: (identity) => dispatch(setIdentity(identity)),
//   };
// };

// export default connect(mapStoreStateToProps, mapActionsToProps)(JoinRoomPage);

import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setIsRoomHost, setIdentity, setRoomId } from "../store/actions";
import JoinRoomTitle from "./JoinRoomTitle";
import JoinRoomContent from "./JoinRoomContent";
import { useHistory } from "react-router-dom";
import { getRoomExists } from "../utils/api";

import "./JoinRoomPage.css";

const JoinRoomPage = (props) => {
  const { setIsRoomHostAction, setIdentityAction, setRoomIdAction, isRoomHost } = props;
  const history = useHistory();
  const search = useLocation().search;
  const [errorMessage, setErrorMessage] = useState(null);

  const meetingIdRef = useRef("");

  useEffect(() => {
    const isRoomHost = new URLSearchParams(search).get("host");
    const meetingId = new URLSearchParams(search).get("meetingId");
    const name = new URLSearchParams(search).get("name");

    meetingIdRef.current = meetingId;
    console.log("MI", meetingId, name, isRoomHost);

    if (isRoomHost) {
      setIsRoomHostAction(true);
    }

    if (meetingId) {
      setRoomIdAction(meetingId);
    }

    if (name) {
      setIdentityAction(name);
    }
    
    console.log("MI_111", meetingId, isRoomHost);

    async function handleRoom() {
      const joinRoom = async () => {
        console.log("ountunda", "xxxx");
        const responseMessage = await getRoomExists(meetingIdRef.current);
        console.log(meetingIdRef.current, "MID");

        const { roomExists, full } = responseMessage;

        if (roomExists) {
          if (full) {
            setErrorMessage("Meeting is full. Please try again later.");
          } else {
            // join a room !
            setRoomIdAction(meetingIdRef.current);
            history.push("/room");
          }
        } else {
          setErrorMessage("Meeting not found. Check your meeting id.");
        }
      };

      const createRoom = () => {
        history.push("/room");
      };

      if (name && isRoomHost) {
        createRoom();
      } else if (meetingId && name && !isRoomHost) {
        console.log("got here ....");
        await joinRoom();
      }
    }

    handleRoom();
  }, [search, setIsRoomHostAction, setIdentityAction, setRoomIdAction, history]);

  return (
    <div className="join_room_page_container">
      <div className="join_room_page_panel">
        <JoinRoomTitle isRoomHost={isRoomHost} />
        <JoinRoomContent />
        {errorMessage && <p className="error_message">{errorMessage}</p>}
      </div>
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  console.log(state);
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setRoomIdAction: (roomId) => dispatch(setRoomId(roomId)),
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(JoinRoomPage);