import React from "react";

const ChatLabel = () => {
  return (
    <div className="chat_label_container">
      <p className="chat_label_paragraph">CHAT</p>
    </div>
  );
};

export default ChatLabel;
