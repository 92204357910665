import React, { useState } from "react";
import * as webRTCHandler from "../../utils/webRTCHandler";
import { ReactComponent as MicButtonImg } from "../../resources/images/mic.svg";
import { ReactComponent as MicButtonImgOff } from "../../resources/images/micOff.svg";


const MicButton = () => {
  const [isMicMuted, setIsMicMuted] = useState(false);

  const handleMicButtonPressed = () => {
    webRTCHandler.toggleMic(isMicMuted);

    setIsMicMuted(!isMicMuted);
  };

  return (
    <div className="video_button_container">
      {isMicMuted ? <MicButtonImgOff className="video_button_image" onClick={handleMicButtonPressed}/> :
      <MicButtonImg className="video_button_image" onClick={handleMicButtonPressed}/>}
    </div>
  );
};

export default MicButton;
