// import React, { useState, useEffect } from "react";
// import CameraButton from "./CameraButton";
// import LeaveRoomButton from "./LeaveRoomButton";
// import MicButton from "./MicButton";
// import SwitchToScreenSharingButton from "./SwitchToScreenSharingButton";
// import { connect } from "react-redux";
// import FifteenPopup from "../../components/FifteenPopup";


// const VideoButtons = (props) => {
//   const { connectOnlyWithAudio } = props;

//   const [timeLeft, setTimeLeft] = useState(60 * 30); // Start with 60 minutes (3600 seconds)

//   useEffect(() => {
//       // If time left is 0, stop the countdown
//       if (timeLeft <= 0) return;

//       // Set an interval to update the timer every second
//       const intervalId = setInterval(() => {
//           setTimeLeft(timeLeft - 1);
//       }, 1000);

//       // Clear interval when component unmounts or timeLeft changes
//       return () => clearInterval(intervalId);
//   }, [timeLeft]);

//   // Function to format the time (hours:minutes:seconds)
//   const formatTime = (seconds) => {
//       const h = Math.floor(seconds / 3600);
//       const m = Math.floor((seconds % 3600) / 60);
//       const s = seconds % 60;
//       return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
//   };

//   return (
//     <div className="video_buttons_container">
//       <MicButton />
//       {!connectOnlyWithAudio && <CameraButton />}
//       <LeaveRoomButton />
//       {!connectOnlyWithAudio && <SwitchToScreenSharingButton />}

//       <span style={{ 
//   color: 'white', 
//   fontSize: '1.2em', 
//   fontWeight: 'bold',
//   marginLeft: '10px'
// }}> {formatTime(timeLeft)} </span>
//     </div>
//   );
// };

// <FifteenPopup />

// const mapStoreStateToProps = (state) => {
//   return {
//     ...state,
//   };
// };

// export default connect(mapStoreStateToProps)(VideoButtons);


import React, { useState, useEffect } from "react";
import CameraButton from "./CameraButton";
import LeaveRoomButton from "./LeaveRoomButton";
import MicButton from "./MicButton";
import SwitchToScreenSharingButton from "./SwitchToScreenSharingButton";
import { connect } from "react-redux";
import FifteenPopup from "../../components/FifteenPopup";

const VideoButtons = (props) => {
  const { connectOnlyWithAudio } = props;
  const [timeLeft, setTimeLeft] = useState(60 * 30); // 30 minutes in seconds
  const [elapsedTime, setElapsedTime] = useState(0); // Track elapsed time
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // If time left is 0, stop the countdown
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
      setElapsedTime(prevElapsed => prevElapsed + 1);
      
      // Show popup after 15 seconds (when elapsedTime reaches 30)
      if (elapsedTime === 899) { // Use 899 because setState is async
        setShowPopup(true);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, elapsedTime]);

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  const handleEndMeeting = () => {
    setShowPopup(false);
    // Add your end meeting logic here
  };

  const handleProceed = () => {
    setShowPopup(false);
    // Meeting continues...
  };


  return (
    <>
      <div className="video_buttons_container mobile_video_buttons_container">
      <span 
          style={{ 
            // color: 'white', 
            fontSize: '1.2em', 
            fontWeight: 'bold',
            marginLeft: '30px'
          }}
        >
          {formatTime(timeLeft)}
        </span>
        <div className="mobile_video_buttons_container_2">
          
          <MicButton />
          {!connectOnlyWithAudio && <CameraButton />}
          <LeaveRoomButton />
          {!connectOnlyWithAudio && <SwitchToScreenSharingButton />}
        </div>
        {/* <span 
          style={{ 
            // color: 'white', 
            fontSize: '1.2em', 
            fontWeight: 'bold',
            marginLeft: '30px'
          }}
        >
          {formatTime(timeLeft)}
        </span> */}

      </div>

      {showPopup && (
        <FifteenPopup 
          onEndMeeting={handleEndMeeting}
          onProceed={handleProceed}
        />
      )}
    </>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(VideoButtons);


// import React, { useState, useEffect } from "react";
// import CameraButton from "./CameraButton";
// import LeaveRoomButton from "./LeaveRoomButton";
// import MicButton from "./MicButton";
// import SwitchToScreenSharingButton from "./SwitchToScreenSharingButton";
// import { connect } from "react-redux";
// import FifteenPopup from "../../components/FifteenPopup";

// const VideoButtons = (props) => {
//   const { connectOnlyWithAudio } = props;
//   const [timeLeft, setTimeLeft] = useState(60 * 30);
//   const [elapsedTime, setElapsedTime] = useState(0);
//   const [showPopup, setShowPopup] = useState(false);

//   useEffect(() => {
//     if (timeLeft <= 0) return;

//     const intervalId = setInterval(() => {
//       setTimeLeft(prevTime => prevTime - 1);
//       setElapsedTime(prevElapsed => prevElapsed + 1);
      
//       if (elapsedTime === 29) {
//         setShowPopup(true);
//       }
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, [timeLeft, elapsedTime]);

//   const formatTime = (seconds) => {
//     const h = Math.floor(seconds / 3600);
//     const m = Math.floor((seconds % 3600) / 60);
//     const s = seconds % 60;
//     return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
//   };

//   const handleEndMeeting = () => {
//     setShowPopup(false);
//   };

//   const handleProceed = () => {
//     setShowPopup(false);
//   };

//   const containerStyle = {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '10px',
//     padding: '10px',
//     width: '100%',
//     '@media (max-width: 768px)': {
//       flexDirection: 'row',
//       justifyContent: 'space-around',
//       gap: '8px',
//       padding: '8px',
//     }
//   };

//   const buttonStyle = {
//     margin: '0 5px',
//     '@media (max-width: 768px)': {
//       margin: '4px',
//       flexBasis: 'auto',
//     }
//   };

//   const timeStyle = {
//     color: 'white',
//     fontSize: '1.2em',
//     fontWeight: 'bold',
//     marginLeft: '10px',
//     '@media (max-width: 768px)': {
//       width: '100%',
//       textAlign: 'center',
//       marginLeft: '0',
//       marginTop: '8px',
//       order: '1'
//     }
//   };

//   return (
//     <>
//       <div style={containerStyle} className="video_buttons_container">
//         <div style={buttonStyle}>
//           <MicButton />
//         </div>
        
//         {!connectOnlyWithAudio && (
//           <div style={buttonStyle}>
//             <CameraButton />
//           </div>
//         )}
        
//         <div style={buttonStyle}>
//           <LeaveRoomButton />
//         </div>
        
//         {!connectOnlyWithAudio && (
//           <div style={buttonStyle}>
//             <SwitchToScreenSharingButton />
//           </div>
//         )}

//         <span style={timeStyle}>
//           {formatTime(timeLeft)}
//         </span>
//       </div>

//       {showPopup && (
//         <FifteenPopup 
//           onEndMeeting={handleEndMeeting}
//           onProceed={handleProceed}
//         />
//       )}
//     </>
//   );
// };

// const mapStoreStateToProps = (state) => {
//   return {
//     ...state,
//   };
// };

// export default connect(mapStoreStateToProps)(VideoButtons);