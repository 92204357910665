import React, { useState, useEffect } from 'react';
import { ReactComponent as TimerImg } from "../../src/resources/images/Timer.svg";


const Timer = () => {
  const [elapsedTime, setElapsedTime] = useState(0);

  // Update elapsed time every second
  useEffect(() => {
    const startTime = sessionStorage.getItem('startTime')
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - startTime;
      setElapsedTime(elapsedTime);
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);


  // Function to format milliseconds to hh:mm:ss format
  const formatTime = (milliseconds) => {
    // Convert milliseconds to seconds
    let totalSeconds = Math.floor(milliseconds / 1000);

    // Calculate hours, minutes, and remaining seconds
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Format into hh:mm:ss
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  };

  // Function to pad single digits with leading zeros
  const padZero = (number) => {
    return number < 10 ? '0' + number : number;
  };

  return (
    <div style={{border: '1px solid #ecedee', borderRadius: "4px", padding:"8px 12px 8px 12px", display:'flex', columnGap: "10px", alignItems: "center"}}>
      <TimerImg />
      {formatTime(elapsedTime)}
    </div>
  );
};

export default Timer;
